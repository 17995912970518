// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
     
const resources = {
  tr: {
    translation: {
      title: "Aloha Dijital",
      navTitle: {
        navLine1: "Anasayfa",
        navLine2: "Hizmetlerimiz",
        navLine3: "Referanslar",
        navLine4: "Hakkımızda",
        navLine5: "İletişim",
      },
      ServiceTitle: {
        service1: "Özelleştirilmiş Web Uygulamaları",
        service2: "Mobil Uygulama Geliştirme",
        service3: "Veri Analitiği ve Raporlama",
        service4: "Veri Tabanı Ve Sunucu Yönetimi",
        service5: "A'dan Z'ye Yazılım Desteği",
        service6: "Kreatif Strateji",
        service7: "Marka Geliştirme",
        service8: "Sosyal Medya Yönetimi",
        service9: "Reklam Yönetimi",
        service10: "SEO ve ASO",
        service11: "Startup Mentörlüğü",
        service12: "Yazılım Eğitimi",
        // service13: "GO Ai",
      },
      home: {
        homeTitle0: "ALOHA DİJİTAL ",
        homeTitle1: "BİLİŞİM",
        homedesc1:
          "Merhaba! Biz Aloha Dijital olarak, dijital dünyada sizi ileri taşıyacak yenilikçi çözümler sunuyoruz. Teknopark'ta yer alan yazılım şirketi olarak sektördeki deneyimimiz ve uzman ekibimizle, işletmenizin dijital varlığını güçlendirmek ve sizi rekabet avantajıyla öne çıkarmak için buradayız. Sizi dinlemek ve işletmenizin ihtiyaçlarına uygun çözümler sunmak için tıklayın ve hemen harekete geçelim.",
        homeContactBtn: "Bizimle iletişime geç",
        homeTitle2: "Hizmetlerimiz",
        homeTitle3: "Projelerimiz",
        projeTitle1: "Aloha Live App",
        proje1desc:
          "Aloha Live App React native ile geliştirdiğimiz kendi uygulamamızdır. Uygulamamız stres ve endişe gibi duygusal zorluklarla başa çıkmayı kolaylaştırırken anonim sohbet sunarak kullanıcıların tanışmadan bir araya gelerek içlerini dökmelerine aracı olmayı amaç edinmiştir. Konuşmacılar içlerini dökebilmekte, dinleyiciler ise konuşmacıları dinleyerek destek sağlayabilmektedirler. Yargılanmadan sohbet etmenin keyifli dünyasına herkesi bekliyoruz.",
        homeButton: "Daha Fazlası",
        projeTitle2: "Kurumsal Web Sitesi",
        proje2desc:
          "Kullanıcı dostu bir arayüz ve hızlı performans sunan kurumsal web site örneğimizdir. React.js ve Node.js kullanarak oluşturulmuştur. React.js, esnek ve modüler bir ön uç sağlar; Node.js ise hızlı ve verimli bir backend altyapısı sunar. Ayrıca, yönetim paneli de React.js ile geliştirilmiştir, bu da işletme süreçlerini kolaylaştırır.",
          homeButton2: "Daha Fazlası",
        projeTitle3: "E-Ticaret Web Sitesi",
        proje3desc:
          "React.js ve Node.js kullanılarak geliştirilmiş modern bir e-ticaret platformudur. React.js'in dinamik kullanıcı arayüzü ve Node.js'in hızlı backend'i sayesinde, kullanıcılar kolayca alışveriş yapabilir ve siparişlerini takip edebilirler. Ayrıca, yönetim paneli de React.js ile geliştirilmiştir, işletme süreçlerini optimize eder.",
          homeButton3: "Daha Fazlası",
        homeTitle4: "YAZILIM AKADEMİSİ",

        academyDesc:
          "Aloha Dijital, yazılım şirketi olarak öncü hizmetler sunmanın yanı sıra Aloha Dijital Akademi olarak da faaliyet gösteriyor. Deneyimli eğitmen kadrosuyla gerçek dünya problemlerini çözmeye odaklanan Akademimiz, yazılım dünyasında kariyer yapmak isteyen herkesi destekliyor. Siz de bizimle teknolojinin gücünü keşfedin ve kariyerinizi ilerletmek için adım atın!",
        homeTitle5: "ALOHA DİJİTAL ",
        homeTitle6: "AKADEMİ",
      },
      references: {
        refTitle: "Aloha Live App",
        refTitleMore: "ile Diledigin Gibi Sohbet Et!",
        refDesc:
          "Anonim sohbet etmenin, dinleyici ya da konuşmacı olmanın eğlenceli dünyası. Aloha Live, gündelik hayattaki zorluklarını anonim olarak paylaşmak isteyenler ve empati duygusu güçlü olup başkalarına destek olmak isteyenler için kullanışlı bir mobil uygulamadır. Ancak uygulamanın bir danışmanlık hizmeti olmadığını ve dikkatli kullanılması gerektiğini unutmamak önemlidir.",
        refBtn: "Daha Fazlası",
        refTitle2: "Aloha Live da",
        refTitleMore2: "Neler Var?",
        liveTitle: "Kupon Kazan",
        liveTitleDesc:
          "Harcadığın coinlerden sana hediye edilen kuponları kullanabilirsin.",
        liveTitle2: "Hediye Gönder",
        liveTitleDesc2:
          "Sohbet arkadaşına küçük sürprizler yapmak için en güzel hediyeler burada.",
        liveTitle3: "Hesap Oluştur",
        liveTitleDesc3: "Birkaç dakikada hesap aç ve kullanmaya başla.",
        liveTitle4: "Dinleyici Ol",
        liveTitleDesc4:
          "Dilersen sadece dinleyici olarak Aloha Live'ı kullanabilirsin.",
        liveTitle5: "Konuşmacı Ol",
        liveTitleDesc5:
          "İstersen sadece konuşmacı olarak da devam edebilirsin.",
        liveTitle6: "ALC Coin Al",
        liveTitleDesc6: "ALC coin alarak dilediğin gibi harcama yapabilirsin.",
      },
      refCenterImage: "/images/appHesap.png",
      // SERVİCES
      advertisingManagement: {
        title: "Reklam Yönetimi",
        advDesc:
          "Firmamız, etkili reklam stratejileri ve kapsamlı yönetim hizmetleriyle müşterilerinin markalarını geniş kitlelere tanıtmalarına yardımcı oluyor. Hedef odaklı yaklaşımımız ve veri analitiği destekli kararlarımızla, reklam harcamalarını optimize ediyor ve maksimum geri dönüş sağlıyoruz.",
        article1:
          "Markanız için en uygun hedef kitleyi belirleyerek, reklam kampanyalarının etkinliğini artırıyoruz.",
        article2:
          "Reklam bütçenize uygun stratejik planlar oluşturarak,maksimum dönüşüm elde etmenizi sağlıyoruz.",
        article3:
          "Çeşitli platformlarda (sosyal medya, arama motorları, display reklamlar vb.) reklam kampanyalarını yöneterek, geniş kitlelere ulaşmanızı sağlıyoruz.",
        article4:
          "Etkili görsel ve metin reklamları tasarlayarak, dikkat çekici ve etkileyici içerikler oluşturuyoruz.",
        article5:
          "Reklam kampanyalarının performansını sürekli olarak izleyerek, stratejileri optimize ediyor ve dönüşüm oranlarını artırıyoruz.",
      },
      brandDevelopment: {
        title: "Marka Geliştirme",
        brdDesc:
          "Firmamız, marka geliştirme konusunda uzmanlaşmıştır ve müşterilerimize markalarını güçlendirmeleri ve büyütmeleri için kapsamlı çözümler sunar. Stratejik planlama, yaratıcı yönetim ve etkili iletişimle, markaların rekabet avantajını artırır ve hedef kitlelerle derin bağlar kurarız.",
        article1:
          "Müşterilerimizin hedeflerine uygun marka stratejileri belirleyerek, marka kimliğini güçlendirme sürecine yön veriyoruz.",
        article2:
          "İkonik logo ve marka tasarımlarıyla, markaların görsel kimliklerini güçlendiriyoruz.",
        article3:
          "Müşteri yolculuğunu optimize ederek, markaların hedef kitleyle etkileşimini artırıyor ve sadık müşteri tabanları oluşturuyoruz.",
        article4:
          "Kapsamlı pazarlama stratejileri geliştirerek, markaların görünürlüğünü artırıyor ve pazar paylarını genişletiyoruz.",
        article5:
          "Markanın performansını sürekli olarak izleyerek,stratejileri optimize ediyor ve marka değerini artırıyoruz.",
      },
      creativestrategy: {
        title: "Kreatif Strateji",
        strgDesc:
          "Firmamız, yenilikçi ve etkileyici kreatif stratejilerle markaların öne çıkmasına yardımcı oluyor. Müşterilerimize benzersiz ve çarpıcı pazarlama yaklaşımları sunarak, marka bilinirliğini artırıyor ve hedef kitlelerle güçlü bir bağ kuruyoruz.",
        article1:
          "Müşterilerimizin benzersiz kimliklerini ortaya çıkarmak ve marka değerlerini güçlendirmek için kapsamlı marka kimliği geliştirme çalışmaları yapıyoruz.",
        article2:
          "Etkileyici görseller, videolar ve metinlerle dikkat çekici içerikler üreterek, markaların hikayelerini en etkili şekilde anlatıyoruz.",
        article3:
          "Kapsamlı dijital pazarlama stratejileriyle, markaların çevrimiçi varlığını güçlendiriyor ve hedef kitlelerle etkileşimi artırıyoruz.",
        article4:
          "Hedef kitleye doğru mesajı iletmek için stratejik iletişim planlaması yapıyor ve markaların doğru platformlarda doğru zamanda görünürlüğünü sağlıyoruz.",
        article5:
          "Pazarlama çabalarının etkinliğini sürekli olarak izleyerek,stratejileri optimize ediyor ve markaların başarılarını artırıyoruz.",
      },
      dataAnalytics: {
        title: "Veri Analitiği ve Raporlama",
        dataAnytcDesc:
          "Firmamız, veri analitiği ve formatlama konularında uzmanlaşmıştır ve müşterilerimize veri odaklı kararlar almalarında yardımcı olmak için kapsamlı çözümler sunar. Güçlü analitik yeteneklerimiz ve veri formatlama becerilerimizle, veri tabanlı stratejiler oluşturarak işletmelerin verimliliğini artırıyoruz.",
        article1:
          "Karmaşık veri kümelerini analiz ederek, veri içerisindeki hataları tespit edip temizleyerek daha doğru sonuçlar elde ediyoruz.",
        article2:
          "Veri görselleştirme ve analitik teknikler kullanarak, müşterilerimizin verilerinden anlamlı içgörüler elde ediyoruz.",
        article3:
          "İhtiyaçlarınıza özel raporlar oluşturarak, verilerinizi anlaşılır bir şekilde sunuyoruz.",
        article4:
          "Farklı veri kaynaklarını entegre ederek, bütünsel bir görünüm sağlıyor ve veri bütünlüğünü koruyoruz.",
        article5:
          "Gelişmiş makine öğrenimi modelleri ile veri tahmini ve gelecek projeksiyonları oluşturarak, karar verme süreçlerinizi güçlendiriyoruz.",
      },
      dbServer: {
        title: "Veri Tabanı Ve Sunucu Yönetimi",
        dbServerDesc:
          "Firmamız, güvenilir veri tabanı yönetimi ve sağlam sunucu altyapısıyla müşterilerinin veri işleme ihtiyaçlarını karşılıyor. Uzman ekibimiz, veri tabanlarını etkin bir şekilde yöneterek işletmelerin veri güvenliğini ve performansını optimize ediyor.",
        article1:
          "Otomatik yedekleme sistemleriyle veri kaybını önler ve acil durumlarda hızlı bir şekilde veri kurtarma sağlarız.",
        article2:
          "Sunucu performansını izleyerek, verimliliği artırmak için gereken optimizasyonları gerçekleştiririz.",
        article3:
          "Güvenlik duvarları ve erişim kontrolleri ile sunucularınızı koruyarak, veri güvenliğini en üst düzeye çıkarırız.",
        article4:
          "Sunucuları sürekli izleyerek, kesintisiz hizmet ve yüksek kullanılabilirlik sağlarız.",
        article5:
          "Uzaktan erişim ve 7/24 teknik destek hizmetleriyle, sorunları hızlıca çözerek kesintisiz bir işletim sağlarız.",
      },
      mobile: {
        title: "Mobil Uygulama Geliştirme",
        mobileDesc:
          "Firmamız, yenilikçi çözümler sunarak müşterilerin dijital ihtiyaçlarını karşılamak için özelleştirilmiş mobil uygulamalar geliştiriyor. Deneyimli bir ekip ve güçlü bir teknoloji altyapısıyla, kullanıcı odaklı mobil deneyimler yaratıyor ve işletmelerin dijital dönüşüm sürecinde lider rol oynuyoruz.",
        article1:
          "iOS ve Android gibi farklı platformlar için uyumlu ve optimize edilmiş uygulamalar geliştiriyoruz.",
        article2:
          "Modern ve kullanıcı dostu arayüzlerle, kullanıcıların uygulamayı kolayca kullanmalarını sağlıyoruz.",
        article3:
          "Hızlı ve verimli uygulamalar geliştirerek, kullanıcıların sorunsuz bir deneyim yaşamalarını sağlıyoruz.",
        article4:
          "Veri güvenliği ve gizliliği konusunda titizlikle çalışarak, kullanıcıların bilgilerini koruyoruz.",
        article5:
          "Uygulamaları sürekli olarak güncelleyerek, yeni özellikler ekliyor ve müşterilerimize kesintisiz teknik destek sağlıyoruz.",
      },
      seoAso: {
        title: "SEO ve ASO",
        seoAsoDesc:
          "Firmamız, SEO (Arama Motoru Optimizasyonu) ve ASO (App Store Optimizasyonu) alanında uzmanlaşmıştır ve müşterilerimize dijital varlıklarını daha görünür hale getirmeleri için kapsamlı çözümler sunar. Arama motorlarında ve uygulama mağazalarında öne çıkmak için stratejik planlama ve etkili optimizasyon stratejileriyle işletmelerin başarısını destekliyoruz.",
        article1:
          "İlgili anahtar kelimeleri belirleyerek, web sitenizin veya uygulamanızın arama sonuçlarında üst sıralarda yer almasını sağlıyoruz.",
        article2:
          "Web sitenizin veya uygulamanızın teknik yapılarını optimize ederek, arama motorlarında ve uygulama mağazalarında daha iyi performans elde etmenizi sağlıyoruz.",
        article3:
          "Etkileyici içerikler oluşturarak, kullanıcıların ilgisini çekiyor ve organik trafik artışını destekliyoruz.",
        article4:
          "Güvenilir web sitelerinden gelen geri bağlantılarla, otoritenizi artırarak arama motorlarında daha yüksek sıralamalara ulaşmanızı sağlıyoruz.",
        article5:
          "SEO ve ASO kampanyalarının performansını düzenli olarak izleyerek, stratejileri optimize ediyor ve maksimum dönüşüm elde etmenizi sağlıyoruz.",
      },
      socialMedia: {
        title: "Sosyal Medya Yönetimi",
        socialMediaDesc:
          "Firmamız, güçlü bir sosyal medya stratejisi ve etkili içerik yönetimiyle markaların dijital varlıklarını güçlendiriyor. Sosyal medya platformlarında etkili bir varlık oluşturarak, hedef kitlelerle etkileşimi artırıyor ve markaların online itibarını güçlendiriyoruz.",
        article1:
          "Markanız için özelleştirilmiş sosyal medya stratejileri belirleyerek, hedeflerinize ulaşmanıza yardımcı oluyoruz.",
        article2:
          "Dikkat çekici görseller, videolar ve metinlerle etkileyici içerikler oluşturarak, markanızın sosyal medya varlığını güçlendiriyoruz.",
        article3:
          "Hedef kitlenizin demografik özelliklerini ve davranışlarını analiz ederek, daha etkili bir sosyal medya stratejisi oluşturuyoruz.",
        article4:
          "Takipçi etkileşimlerini yöneterek, markanızın müşteri memnuniyetini artırıyor ve müşteri desteği sağlıyoruz.",
        article5:
          "Sosyal medya kampanyalarının performansını sürekli olarak izleyerek, stratejileri optimize ediyor ve maksimum etkiyi sağlıyoruz.",
      },
      aZSupport: {
        title: "A'dan Z'ye Yazılım Desteği",
        aZSupportDesc:
          "Firmamız, müşterilerimize A'dan Z'ye tam kapsamlı yazılım desteği sağlamaktadır. Deneyimli bir ekip ve çeşitli teknoloji uzmanlığıyla, yazılım projelerinizin her aşamasında size yardımcı oluyoruz. Güvenilir ve etkili çözümlerimizle işletmenizin başarısını destekliyoruz.",
        article1:
          "İşletmenizin gereksinimlerini anlamak ve en uygun çözümleri sunmak için kapsamlı ihtiyaç analizi ve danışmanlık hizmetleri sunuyoruz.",
        article2:
          "Yazılım projenizin her aşamasında stratejik bir planlama ve etkili bir geliştirme süreci sağlıyoruz.",
        article3:
          "Kullanıcı odaklı arayüz tasarımıyla, kullanıcıların rahatlıkla etkileşimde bulunabileceği uygulamalar geliştiriyoruz.",
        article4:
          "Yazılımın güvenilirliğini sağlamak için titiz kalite kontrol ve test süreçleri uyguluyoruz.",
        article5:
          "Proje sonrası sürekli teknik destek ve bakım hizmetleriyle, yazılımınızın performansını ve güvenilirliğini koruyoruz.",
      },
      softwareTraining: {
        title: "Yazılım Eğitimi",
        softwareTrainingDesc:
          "Firmamız, geleceğin teknoloji liderlerini yetiştirmek amacıyla kapsamlı yazılım eğitimi programları sunmaktadır. Deneyimli eğitmenlerimizle birlikte, katılımcıların yazılım dünyasında ustalaşmalarını ve kariyerlerini geliştirmelerini sağlayacak pratik beceriler kazanmalarını hedefliyoruz.",
        article1:
          "Temel bilgilerden ileri düzeye kadar geniş bir yelpazede yazılım eğitim programları sunarak, katılımcıların ilgi alanlarına ve ihtiyaçlarına uygun eğitim seçenekleri sunuyoruz.",
        article2:
          "Farklı öğrenme stillerine ve zaman dilimlerine uygun esnek eğitim programları sunarak, katılımcıların öğrenme deneyimlerini optimize ediyoruz.",
        article3:
          "Katılımcıların kariyer hedeflerine ulaşmalarına yardımcı olmak için kişiselleştirilmiş kariyer danışmanlığı ve destek sağlıyoruz.",
        article4:
          "Yazılım endüstrisindeki güncel gelişmeleri takip ederek, sektörle ilişkiler kurmayı teşvik ediyor ve katılımcıların profesyonel ağlarını genişletmelerini sağlıyoruz.",
        article5:
          "Proje sonrası sürekli teknik destek ve bakım hizmetleriyle, yazılımınızın performansını ve güvenilirliğini koruyoruz.",
        trainingBtn: "Eğitim İçeriği ve Fiyatlar",
        cubeTitle: "Eğitim Modelimizde Neler Var?",
        cubeArticle1: "Teknik Eğitim",
        cubeArticle2: "Projeler",
        cubeArticle3: "Staj İmkanı",
        cubeArticle4: "İş İmkanı",
        cubeArticle5: "Teknik Eğitim",
        cubeArticle6: "Projeler",
        cubeArticle7: "Staj İmkanı",
        cubeArticle8: "İş İmkanı",
        cubeDesc1:
          "Eğitimimizde verdiğimiz Backend ve Frontend developer eğitimimizde (FSD) eğitim süreci teknik eğitim ile başlar. Mutlaka yoğun bir teknik eğitim süreci veriyoruz. 1 gün arayla haftada en az 4 gün ders vererek ve eğitimden kopmanızı engelleyerek bu süreci yönetiyoruz.",
        cubeDesc2:
          "Teknik eğitim süresince ödev ve projeler yapıyorsunuz. Bu süreç bitince size burada öğrendiğiniz herşeyi kullanark yapacağınızı bir proje veriyoruz. Bu projeyi kendiniz bizim mentörlüğümüzle verilen sürede yapıyorsunuz. Özellikle CV nize ekleyeceğiniz bir proje oluyor.",
        cubeDesc3:
          "Projelerden başarılı olan öğrencilerimizi kendi yazılım ekibimizle staja alıyoruz. Staj uzaktan çalışma şeklinde yapılıyor. Sataj süresince her gün ekibin günlük toplantılarına katılıyorsunuz, gerçek müşteri projelerinde verilen görevleri yaparak sektör alanında deneyim kazanmış oluyorsunuz.",
        cubeDesc4:
          "Sektörde artan yazılımcı ihtiyacını karşılamak için Trükiye nin en büyük teknoloji platformu ile işbirliği yaptık. Bu kadar büyük bir network içerisinde bize gelen yazılımcı taleplerine kendi öğrencilerimizi yönlendiriyoruz. Bu süreçte de danışmanlık yapıyoruz.",
      },
      startUp: {
        title: "Startup Mentörlüğü",
        startUpDesc:
          "Firmamız, girişimcilere yönelik startup mentörlüğü hizmetleri sunarak, yenilikçi fikirleri olan girişimcilerin başarılı bir şekilde büyümelerine ve gelişmelerine yardımcı oluyor. Deneyimli mentörlerimizle birlikte, girişimcilerin yolculuğunda rehberlik ediyor ve başarılı bir gelecek için stratejik danışmanlık sağlıyoruz.",
        article1:
          "Yenilikçi fikirlerinizi gerçeğe dönüştürmek için stratejik planlama süreçlerine rehberlik ediyor ve etkili iş modelleri oluşturmanıza yardımcı oluyoruz.",
        article2:
          "Pazarınızı anlamak için kapsamlı bir araştırma yaparak, doğru hedef kitleye ulaşmanızı sağlıyoruz.",
        article3:
          "Finansal süreçlerinizi yöneterek, işletme sermayesi ve yatırım stratejileri konusunda size rehberlik ediyoruz.",
        article4:
          "Etkili iletişim ve networking becerileri kazanmanızı sağlayarak, girişimcilik ekosistemine entegre olmanıza yardımcı oluyoruz.",
        article5:
          "Bire bir mentorluk seansları düzenleyerek, bireysel ihtiyaçlarınıza uygun özelleştirilmiş destek sağlıyoruz ve girişimcilik yolculuğunuzda size yol gösteriyoruz.",
      },
      web: {
        title: "Özelleştirilmiş Web Uygulamaları",
        webDesc:
          "Firmamız, müşterilerin ihtiyaçlarına özel tasarlanmış web uygulamaları geliştirerek dijital varlıklarını güçlendiriyor. Deneyimli ekip ve yenilikçi yaklaşımımızla, işletmelerin benzersiz gereksinimlerini karşılayacak özelleştirilmiş çözümler sunuyoruz.",
        article1:
          "Her müşterinin benzersiz gereksinimlerini karşılamak için web uygulamalarını tamamen özelleştiriyoruz.",
        article2:
          "Kullanıcı dostu arayüzler ve özel işlevselliklerle müşterilerinizin deneyimini artırın.",
        article3:
          "Veri güvenliği ve gizliliğine büyük önem veriyor, endüstri standartlarını aşan koruma sağlıyoruz.",
        article4:
          "Farklı platformlarla sorunsuz entegrasyon sağlayarak iş süreçlerinizi optimize ediyoruz.",
        article5:
          "Proje sonrası süreçte kesintisiz teknik destek ve bakım hizmetleri sunarak, müşteri memnuniyetini devam ettiriyoruz.",
      },
      moreInfoBtn: {
        infoBtn: "Daha Fazla Bilgi Al",
      },

      formPopup: {
        popupDesc:
          "Mesajınız alınmıştır, en kısa zamanda size geri dönüş yapacağız.",
      },
      about: {
        aboutTitle1: "Açık Bir Misyon",
        aboutDesc1:
          "Müşteri zorluklarını anlayarak, yenilikçi yazılım çözümleri geliştiriyoruz. Teknolojiyi insanların günlük yaşamlarına entegre ederek yaşam kalitesini artırmayı hedefliyoruz. Kullanıcı deneyimini iyileştirerek bağlantı kurmayı kolaylaştıran çözümler sunuyoruz. Sürdürülebilirlik ilkesiyle topluma ve çevreye katkı sağlıyoruz.",
        aboutTitle2: "Net Bir Vizyon",
        aboutDesc2:
          "Geleceğe ilham vermek için buradayız. Teknolojiyi kullanarak insanların hayatlarını daha kolay, verimli ve anlamlı hale getirme vizyonumuz var. Öncü bir yazılım şirketi olarak, geleceğin ihtiyaçlarını öngörerek çözümler geliştiriyoruz. Dürüstlük, şeffaflık ve sürdürülebilirlik ilkelerine bağlıyız. Küresel düzeyde etkili bir rol üstlenerek, insanlığın karşılaştığı sorunlara çözümler sunmayı amaçlıyoruz.",
      },
      team: {
        teamPosition1: "Önder Kurucu ve Partner-CEO",
        teamDesc1:
          "İstanbul Üniversitesi İngilizce İktisat Bölümü mezunu ve Marmara Üniversitesi Bankacılık ve Sigortacılık Bölümü'nde Doktorasını yapıyor. 20 yıl bankacılık sektöründe ekonomist olarak çalıştı ve son 10 yıldır Kadir Has Üniversitesi'nde öğretim Bankacılık ve Sigortacılık Bölümün'de görevlisi olarak Risk Yönetimi, Fon Yönetimi, Kredi Analizi ve Müşteri İlişkileri Yönetimi derslerini veriyor.",
        teamPosition2: "Partner-İş Geliştirme Direktörü",
        teamDesc2:
          "Fen Bilimleri eğitimi ve Endüstri Mühendisliği diplomasına sahip olup, Global'de özellikle Avrupa, CIS, LATAM ve MENA'da Katma Değerli Hizmetler alanında 15 yıllık deneyime sahiptir. Bilgi teknolojisi ve hizmetleri sektöründe kanıtlanmış bir çalışma geçmişine sahiptir. Pazarlama Yönetimi, İş Planlaması, CRM, Ekip Oluşturma ve Yönetim alanlarında deneyimli ve girişimcilik konusunda uzmandır.",
        teamPosition3: "Partner-Kurumsal İletişim Direktörü",
        teamDesc3:
          "Prof. Dr. Özgen 1993 yılında Marmara Üniversitesi İletişim Fakültesi Halkla İlişkiler ve Tanıtım Bölümü’nden mezun olduktan sonra aynı yıl Marmara Üniversitesi Sosyal Bilimler Enstitüsü Halkla İlişkiler Yüksek Lisans Programındaki eğitimine ve aynı zamanda İletişim Fakültesi’nde araştırma görevlisi olarak görevine başlamıştır. 2001 yılında Yardımcı Doçent olan Özgen, 2012 yılında Doçent ve 2018 yılında Profesör olmuştur. Halkla İlişkiler, Marka İletişimi, Yeni Medya, Retorik ve Eleştirel Yaklaşım, Sosyal Sorumluluk, Yeni Medya ve Markalama gibi pek çok konuda dersler vermektedir.",
      },
      wpIcon: {
        desc: "Bizimle iletişime geç",
      },
      meetUs: {
        meetTitle: "Bizimle Tanışın",
      },
      contact: {
        contactTitle: "Bizimle İletişime Geçin",
        contactTitle2: "Hemen Sana Ulaşalım",
        firstName: "Adınız",
        lastName: "Soyadınız",
        mail: "E-posta",
        message: "Mesajınız...",
        submit: "Gönder",
        adress: "ADRESİMİZ",
        adressDetail:
          "Marmara Üniversitesi Teknopark, Başıbüyük Mah. Süreyyapaşa Başıbüyük Yolu Sk. 4/1 İç Kapı No:1 Maltepe/İstanbul",
        sosyalMedia: "SOSYAL MEDYA",
        mailus: "E-POSTA İLETİN",
        office: "Ofisimiz",
      },
      footer: {
        reserved: "Aloha Dijital. Tüm hakları saklıdır.",
        kvkk: "Aloha Dijital KVKK",
      },
    },
  },
  en: {
    translation: {
      title: "Aloha Dijital Software",
      navTitle: {
        navLine1: "Home",
        navLine2: "Our Services",
        navLine3: "References",
        navLine4: "About",
        navLine5: "Contact",
      },
      ServiceTitle: {
        service1: "Customized Web Applications",
        service2: "Mobile Application Development",
        service3: "Data Analytics and Reporting",
        service4: "Database and Server Management",
        service5: "Software Support from A to Z",
        service6: "Creative Strategy",
        service7: "Brand Development",
        service8: "Social Media Management",
        service9: "Advertising Management",
        service10: "SEO and ASO",
        service11: "Startup Mentorship",
        service12: "Software Training",
      },
      home: {
        homeTitle0: "ALOHA DIJITAL ",
        homeTitle1: "TECHNOLOGY",
        homedesc1:
          "Hello! As Aloha Dijital, we offer innovative solutions that will take you forward in the digital world. As a software company located in Technopark, we are here to strengthen the digital presence of your business and make you stand out with a competitive advantage, with our experience in the sector and our expert team. Click to listen to you and offer solutions that suit the needs of your business and let's take action immediately.",
        homeContactBtn: "Contact Us",
        homeTitle2: "Services",
        homeTitle3: "Our Projects",
        projeTitle1: "Aloha Live App",
        proje1desc:
          "Aloha Live App is our own application developed with React native. While our application makes it easier to cope with emotional difficulties such as stress and anxiety, it aims to be a tool for users to come together and pour out their inner feelings by offering anonymous chat. Speakers can pour out their feelings, and the audience can provide support by listening to the speakers. We welcome everyone to the pleasant world of chatting without being judged.",
        homeButton: "More",
        projeTitle2: "Corporate Website",
        proje2desc:
          "It is our example of a corporate website that offers a user-friendly interface and fast performance. Built using React.js and Node.js. React.js provides a flexible and modular front end; Node.js offers a fast and efficient backend infrastructure. Additionally, the administration panel is also developed with React.js, which simplifies business processes.",
        projeTitle3: "E-Commerce Website",
        proje3desc:
          "It is a modern e-commerce website developed using React.js and Node.js. Thanks to the dynamic user interface of React.js and the fast backend of Node.js, users can easily shop and track their orders. Additionally, the administration panel is also developed with React.js, optimizing business processes.",
        homeTitle4: "SOFTWARE ACADEMY",
        homeTitle5: "ALOHA DIJITAL ",
        homeTitle6: "ACADEMY",
        academyDesc:
          "In addition to providing pioneering services as a software company, Aloha Dijital also operates as Aloha Dijital Academy. Our Academy, which focuses on solving real-world problems with its experienced instructor staff, supports everyone who wants to make a career in the software world. Discover the power of technology with us and take steps to advance your career!",
      },
      references: {
        refTitle: "With Aloha Live",
        refTitleMore: "Chat As You Wish!",
        refDesc:
          "The fun world of chatting anonymously, being a listener or speaker. Aloha Live is a useful mobile application for those who want to share their difficulties in daily life anonymously and for those who have a strong sense of empathy and want to support others. However, it is important to remember that the application is not a consultancy service and should be used with caution.",
        refBtn: "More",
        refTitle2: "Aloha Live",
        refTitleMore2: "What is There",
        liveTitle: "Win Coupon",
        liveTitleDesc:
          "You can use the coupons given to you from the coins you spend.",
        liveTitle2: "Send a Gift",
        liveTitleDesc2:
          "The best gifts to make little surprises for your chat friend are here.",
        liveTitle3: "Create Account",
        liveTitleDesc3: "Open an account and start using it in a few minutes.",
        liveTitle4: "Be a Listener",
        liveTitleDesc4:
          "If you wish, you can use Aloha Live as a listener only.",
        liveTitle5: "Become a Speaker",
        liveTitleDesc5: "If you want, you can continue as a speaker only.",
        liveTitle6: "Buy ALC Coins",
        liveTitleDesc6: "You can spend as you wish by purchasing ALC coins.",
      },
      refCenterImage: "/images/acountIconEn.png",
      // SERVİCES
      advertisingManagement: {
        title: "Advertising Management",
        advDesc:
          "Our company helps its customers introduce their brands to large audiences with effective advertising strategies and comprehensive management services. With our target-oriented approach and data analytics-supported decisions, we optimize advertising expenditures and provide maximum return.",
        article1:
          "By determining the most suitable target audience for your brand, we increase the effectiveness of advertising campaigns.",
        article2:
          "We ensure that you achieve maximum conversions by creating strategic plans suitable for your advertising budget.",
        article3:
          "We enable you to reach large audiences by managing advertising campaigns on various platforms (social media, search engines, display ads, etc.).",
        article4:
          "We create eye-catching and impressive content by designing effective visual and text ads.",
        article5:
          "By constantly monitoring the performance of advertising campaigns, we optimize strategies and increase conversion rates.",
      },
      brandDevelopment: {
        title: "Brand Development",
        brdDesc:
          "Our company specializes in brand development and offers our customers comprehensive solutions to strengthen and grow their brands. With strategic planning, creative management and effective communication, we increase the competitive advantage of brands and establish deep connections with target audiences.",
        article1:
          "We direct the process of strengthening the brand identity by determining brand strategies that suit our customers' goals.",
        article2:
          "We strengthen the visual identities of brands with iconic logo and brand designs.",
        article3:
          "By optimizing the customer journey, we increase brands' interaction with the target audience and create loyal customer bases.",
        article4:
          "By developing comprehensive marketing strategies, we increase the visibility of brands and expand their market shares.",
        article5:
          "By constantly monitoring the brand's performance, we optimize strategies and increase brand value.",
      },
      creativestrategy: {
        title: "Creative Strategy",
        strgDesc:
          "Our company helps brands stand out with innovative and impressive creative strategies. By offering unique and striking marketing approaches to our customers, we increase brand awareness and establish a strong bond with target audiences.",
        article1:
          "We carry out comprehensive brand identity development studies to reveal the unique identities of our customers and strengthen their brand values.",
        article2:
          "We tell the stories of brands in the most effective way by producing remarkable content with impressive images, videos and texts.",
        article3:
          "With comprehensive digital marketing strategies, we strengthen the online presence of brands and increase interaction with target audiences.",
        article4:
          "We make strategic communication planning to convey the right message to the target audience and ensure the visibility of brands on the right platforms at the right time.",
        article5:
          "By constantly monitoring the effectiveness of marketing efforts, we optimize strategies and increase the success of brands.",
      },
      dataAnalytics: {
        title: "Data Analytics and Reporting",
        dataAnytcDesc:
          "Our firm specializes in data analytics and formatting and offers comprehensive solutions to help our clients make data-driven decisions. With our strong analytical capabilities and data formatting skills, we increase the efficiency of businesses by creating data-based strategies.",
        article1:
          "We obtain more accurate results by analyzing complex data sets, detecting and clearing errors in the data.",
        article2:
          "Using data visualization and analytical techniques, we gain meaningful insights from our customers' data.",
        article3:
          "We present your data in an understandable way by creating reports specific to your needs.",
        article4:
          "By integrating different data sources, we provide a holistic view and maintain data integrity.",
        article5:
          "We strengthen your decision-making processes by creating data predictions and future projections with advanced machine learning models.",
      },
      dbServer: {
        title: "Database and Server Management",
        dbServerDesc:
          "Our company meets the data processing needs of its customers with reliable database management and solid server infrastructure. Our expert team optimizes the data security and performance of businesses by effectively managing databases.",
        article1:
          "We prevent data loss with automatic backup systems and provide rapid data recovery in case of emergency.",
        article2:
          "By monitoring server performance, we make the necessary optimizations to increase efficiency.",
        article3:
          "We maximize data security by protecting your servers with firewalls and access controls.",
        article4:
          "By constantly monitoring the servers, we ensure uninterrupted service and high availability.",
        article5:
          "With remote access and 24/7 technical support services, we ensure uninterrupted operation by quickly solving problems.",
      },
      mobile: {
        title: "Mobile Application Development",
        mobileDesc:
          "Our company develops customized mobile applications to meet customers' digital needs by providing innovative solutions. With an experienced team and a strong technology infrastructure, we create user-focused mobile experiences and play a leading role in the digital transformation process of businesses.",
        article1:
          "We develop compatible and optimized applications for different platforms such as iOS and Android.",
        article2:
          "With modern and user-friendly interfaces, we enable users to use the application easily.",
        article3:
          "By developing fast and efficient applications, we ensure that users have a smooth experience.",
        article4:
          "We protect users' information by working meticulously on data security and privacy.",
        article5:
          "We constantly update applications, add new features and provide uninterrupted technical support to our customers.",
      },
      seoAso: {
        title: "SEO and ASO",
        seoAsoDesc:
          "Our company specializes in SEO (Search Engine Optimization) and ASO (App Store Optimization) and offers our customers comprehensive solutions to make their digital assets more visible. We support the success of businesses with strategic planning and effective optimization strategies to stand out in search engines and app stores.",
        article1:
          "By identifying relevant keywords, we ensure that your website or application ranks high in search results.",
        article2:
          "By optimizing the technical structures of your website or application, we enable you to achieve better performance in search engines and application stores.",
        article3:
          "By creating impressive content, we attract users' attention and support organic traffic growth.",
        article4:
          "With backlinks from reliable websites, we increase your authority and help you achieve higher rankings in search engines.",
        article5:
          "By regularly monitoring the performance of SEO and ASO campaigns, we optimize strategies and ensure you achieve maximum conversions.",
      },
      socialMedia: {
        title: "Social Media Management",
        socialMediaDesc:
          "Our company strengthens the digital assets of brands with a strong social media strategy and effective content management. By creating an effective presence on social media platforms, we increase interaction with target audiences and strengthen the online reputation of brands.",
        article1:
          "We help you achieve your goals by determining customized social media strategies for your brand.",
        article2:
          "We strengthen the brand's social media presence by creating impressive content with eye-catching images, videos and texts.",
        article3:
          "By analyzing the demographic characteristics and behavior of your target audience, we create a more effective social media strategy.",
        article4:
          "By managing follower interactions, we increase your brand's customer satisfaction and provide customer support.",
        article5:
          "By constantly monitoring the performance of social media campaigns, we optimize strategies and ensure maximum impact.",
      },
      aZSupport: {
        title: "Software Support from A to Z",
        aZSupportDesc:
          "Our company provides fully comprehensive software support to our customers from A to Z. With an experienced team and diverse technology expertise, we assist you at every stage of your software projects. We support the success of your business with our reliable and effective solutions.",
        article1:
          "We offer comprehensive needs analysis and consultancy services to understand the needs of your business and offer the most appropriate solutions.",
        article2:
          "We provide strategic planning and an effective development process at every stage of your software project.",
        article3:
          "With user-oriented interface design, we develop applications that users can easily interact with.",
        article4:
          "We implement rigorous quality control and testing processes to ensure the reliability of the software.",
        article5:
          "We maintain the performance and reliability of your software with continuous post-project technical support and maintenance services.",
      },
      softwareTraining: {
        title: "Software Training",
        softwareTrainingDesc:
          "Our company offers comprehensive software training programs to train future technology leaders. Together with our experienced instructors, we aim for participants to gain practical skills that will enable them to master the software world and develop their careers.",
        article1:
          "By offering a wide range of software training programs, from basics to advanced, we offer training options that suit the interests and needs of the participants.",
        article2:
          "We optimize participants' learning experiences by offering flexible training programs suitable for different learning styles and time periods.",
        article3:
          "We provide personalized career counseling and support to help participants achieve their career goals.",
        article4:
          "By following current developments in the software industry, we encourage establishing relationships with the industry and enable participants to expand their professional networks.",
        article5:
          "We maintain the performance and reliability of your software with continuous post-project technical support and maintenance services.",
        trainingBtn: "Training Content and Prices",
        cubeTitle: "What's in Our Education Model?",
        cubeArticle1: "Technical Education",
        cubeArticle2: "Projects",
        cubeArticle3: "Internship Opportunity",
        cubeArticle4: "Job opportunity",
        cubeArticle5: "Vocational",
        cubeArticle6: "Projects",
        cubeArticle7: "Internship",
        cubeArticle8: "Employment",
        cubeDesc1:
          "In the Backend and Frontend developer training (FSD) we provide in our training, the training process starts with technical training. We definitely provide an intensive technical training process. We manage this process by giving lessons at least 4 days a week with 1 day intervals and preventing you from breaking away from education.",
        cubeDesc2:
          "During technical education, you do homework and projects. When this process is completed, we give you a project that you will make using everything you have learned here. You do this project yourself, with our mentorship, within the given time. It is especially a project that you can add to your CV.",
        cubeDesc3:
          "We take our students who are successful in the projects into internships with our own software team. The internship is done remotely. During the sales period, you attend the daily meetings of the team every day, and you gain experience in the sector by doing the tasks assigned to real customer projects.",
        cubeDesc4:
          "We cooperated with Turkey's largest technology platform to meet the increasing need for software developers in the sector. We direct our own students to the requests for software developers that come to us within such a large network. We also provide consultancy in this process.",
      },
      startUp: {
        title: "Startup Mentorship",
        startUpDesc:
          "By providing startup mentoring services for entrepreneurs, our company helps entrepreneurs with innovative ideas grow and develop successfully. Together with our experienced mentors, we guide entrepreneurs on their journey and provide strategic consultancy for a successful future.",
        article1:
          "We guide strategic planning processes and help you create effective business models to turn your innovative ideas into reality.",
        article2:
          "By conducting comprehensive research to understand your market, we ensure that you reach the right target audience.",
        article3:
          "We manage your financial processes and guide you on working capital and investment strategies.",
        article4:
          "We help you integrate into the entrepreneurship ecosystem by ensuring you gain effective communication and networking skills.",
        article5:
          "By organizing one-on-one mentoring sessions, we provide customized support tailored to your individual needs and guide you on your entrepreneurial journey.",
      },
      web: {
        title: "Customized Web Applications",
        webDesc:
          "Our company strengthens its digital presence by developing web applications specifically designed for customers' needs. With our experienced team and innovative approach, we offer customized solutions to meet the unique needs of businesses.",
        article1:
          "We fully customize web applications to meet each customer's unique requirements.",
        article2:
          "Enhance your customers' experience with user-friendly interfaces and custom functionalities.",
        article3:
          "We attach great importance to data security and privacy, providing protection that exceeds industry standards.",
        article4:
          "We optimize your business processes by providing seamless integration with different platforms.",
        article5:
          "We continue customer satisfaction by providing uninterrupted technical support and maintenance services in the post-project period.",
      },
      // SERVİCES AND
      moreInfoBtn: {
        infoBtn: "Learn More",
      },

      formPopup: {
        popupDesc:
          "Your message has been received, we will get back to you as soon as possible.",
      },
      about: {
        aboutTitle1: "A Clear Mission",
        aboutDesc1:
          "By understanding customer challenges, we develop innovative software solutions. We aim to improve the quality of life by integrating technology into people's daily lives. We offer solutions that make connecting easier by improving user experience. We contribute to society and the environment with the principle of sustainability.",
        aboutTitle2: "A Clear Vision",
        aboutDesc2:
          "We are here to inspire the future. We have a vision of making people's lives easier, more efficient and more meaningful by using technology. As a leading software company, we develop solutions by anticipating the needs of the future. We are committed to the principles of honesty, transparency and sustainability. We aim to provide solutions to the problems faced by humanity by assuming an effective role at the global level.",
      },
      team: {
        teamPosition1: "Founder - Leader and Partner-CEO",
        teamDesc1:
          "He is a graduate of the English Economics Department of Istanbul University and is currently pursuing his Ph.D. in Banking and Insurance at Marmara University. He has worked as an economist in the banking sector for 20 years and has been teaching courses in Risk Management, Fund Management, Credit Analysis, and Customer Relationship Management at Kadir Has University in the Banking and Insurance Department for the past 10 years.",
        teamPosition2: "Partner-Business Development Director",
        teamDesc2:
          "With a background in Science and a degree in Industrial Engineering, they hold 15 years of experience in Value-Added Services, particularly in Europe, CIS, LATAM, and MENA regions. They possess a proven track record in the information technology and services sector. Experienced in Marketing Management, Business Planning, CRM, Team Building, and Management, they are also an expert in entrepreneurship.",
        teamPosition3: "Partner-Corporate Communications Director",
        teamDesc3:
          "Professor Dr. Özgen graduated from the Public Relations and Promotion Department of the Faculty of Communication at Marmara University in 1993. In the same year, he started his education in the Public Relations Master's Program at the Institute of Social Sciences at Marmara University and simultaneously began working as a research assistant in the Faculty of Communication. Özgen became an Assistant Professor in 2001, an Associate Professor in 2012, and a full Professor in 2018. He teaches courses on various topics such as Public Relations, Brand Communication, New Media, Rhetoric and Critical Approach, Social Responsibility, New Media, and Branding.",
      },
      wpIcon: {
        desc: "Contact with us",
      },
      meetUs: {
        meetTitle: "Meet Us",
      },
      contact: {
        contactTitle: "Contact With Us",
        contactTitle2: "We'll reach out to you shortly.",
        firstName: "Your Name",
        lastName: "Your Lastname",
        mail: "E-mail",
        message: "Message...",
        submit: "Send",
        adress: "OUR ADDRESS",
        adressDetail:
          "Marmara University Technopark, Basibuyuk Dist. Süreyyapasa Basibuyuk St. 4/1  No:1 Maltepe/Istanbul",
        sosyalMedia: "SOCIAL MEDIA",
        mailus: "SEND EMAIL",
        office: "Our Office",
      },
      footer: {
        reserved: "Aloha Dijital. All rights reserved.",
        kvkk: "Aloha Dijital PDPL",
      },
    },
  },
};

function updateTitle() {
  const newTitle = i18n.t("title");
  document.getElementById("dynamicTitle").innerText = newTitle;
}

// Dil değiştiğinde başlık metnini güncellemek için i18next olay dinleyicisini tanımlayın
i18n.on("languageChanged", () => {
  updateTitle(); // Dil değiştiğinde başlık metnini güncelleyin
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    returnObjects: true,
    lng: "tr", // Başlangıç dili
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
